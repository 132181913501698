import React from "react"
import PageTemplate from "../components/pageTemplate"

let About = () => (
  <PageTemplate title="About Us">
    <p>
      A software development company based in London ,United Kingdom and Bangalore, India specializing in the design and build of mobile apps and websites. We assist our clients in delivering the best possible user experience within their specific software and business demands. We have a proven and repeatable methodology and years of web and mobile app development expertise . We can help you take your business to the global online space through an array of services covering cross platform mobile application development, iOS app development, Android app development, Hybrid app development, responsive website design & web application development.
    </p>
    <p>
      
    </p>
    <p>
      
    </p>
  </PageTemplate>
)

export default About
